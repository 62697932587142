exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-project-index-js": () => import("./../../../src/pages/project/index.js" /* webpackChunkName: "component---src-pages-project-index-js" */),
  "component---src-pages-project-tpp-js": () => import("./../../../src/pages/project/tpp.js" /* webpackChunkName: "component---src-pages-project-tpp-js" */),
  "component---src-pages-resume-js": () => import("./../../../src/pages/resume.js" /* webpackChunkName: "component---src-pages-resume-js" */),
  "component---src-pages-update-js": () => import("./../../../src/pages/update.js" /* webpackChunkName: "component---src-pages-update-js" */),
  "component---src-templates-update-post-js": () => import("./../../../src/templates/update-post.js" /* webpackChunkName: "component---src-templates-update-post-js" */)
}

